import { useEffect, useState } from "react"
import Storage from "@/lib/storage"

export default function useLocalShop() {
  const defaultObject = {
    hasCart: false
  }
  const [object, setObject] = useState(defaultObject)
  const [loading, setLoading] = useState(true)

  const setCart = () => {
    const data = { ...defaultObject, hasCart: true }
    Storage.set("shop", data)
    setObject(data)
  }

  const deleteCart = () => {
    Storage.remove("shop")
    setObject(defaultObject)
  }

  useEffect(() => {
    const local = Storage.get("shop", defaultObject)
    setObject(local)
    setLoading(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return {
    shop: object,
    hasCart: object.hasCart,
    loading,
    setCart,
    deleteCart
  }
}
