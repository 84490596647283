import React, { createContext, useContext, useReducer } from "react"
import { requestNameCollectionCount } from "@/lib/api/gravityForms"

export const NameCollectionContext = createContext()
export const useNameCollectionContext = () => useContext(NameCollectionContext)

export const NameCollectionProvider = ({ formId, startAt, children }) => {
  const defaultState = {
    count: null
  }

  const [state, dispatch] = useReducer((state, action) => {
    switch (action.type) {
      case "SET_COUNT": {
        return { ...state, count: action.count }
      }
      default:
        return state
    }
  }, defaultState)

  const setCount = (count) => {
    dispatch({ type: "SET_COUNT", count })
  }

  const refreshCount = async () => {
    if (!formId) {
      return
    }

    requestNameCollectionCount(formId).then((response) => {
      setCount(startAt + response.count)
    })
  }

  return (
    <NameCollectionContext.Provider
      value={{ count: state.count, setCount, refreshCount }}>
      {children}
    </NameCollectionContext.Provider>
  )
}
