import { useEffect, useRef } from "react"
import classNames from "classnames"
import dynamic from "next/dynamic"

import { useMeta, usePathData, useAppContext } from "@/lib/contexts/appContext"
import { useCartContext } from "@/components/woocommerce/cartStore"
import useLocalShop from "@/lib/hooks/useLocalShop"
import LogoSpinner from "@/components/common/loading/logo-spinner"

import Cart from "../common/icons/cart"
import Close from "../common/icons/close"

import useOnClickOutside from "../../lib/hooks/useOnClickOutside"
import styles from "./ToggleCart.module.css"

const MiniCart = dynamic(() => import("./../woocommerce/MiniCart"), {
  ssr: false
})

const ToggleCart = ({ isActive, setActive }) => {
  const { path } = usePathData()
  const { object } = useAppContext()
  const { woocommerce } = useMeta()
  const {
    shop_enabled,
    checkout_url,
    i18n: { minicart: { header } = {} } = {}
  } = woocommerce

  const { data, isLoading, fetchCart } = useCartContext()
  const { hasCart } = useLocalShop()
  const { items_count } = data

  const isCheckout = checkout_url
    ? checkout_url.includes(path) && path !== "/"
    : false

  const cartRef = useRef(null)
  useOnClickOutside(cartRef, () => setActive("cart", false), isActive)

  useEffect(() => {
    if (!shop_enabled || object?.theme?.hideCart) {
      return
    }

    if (hasCart) {
      fetchCart()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasCart])

  if (!shop_enabled || isCheckout || object.theme?.hideCart) {
    return null
  }

  const Icon = ({ isActive, isLoading }) => {
    if (isLoading) {
      return <LogoSpinner className="w-6 h-auto opacity-50" />
    }
    return isActive ? <Close className="close" /> : <Cart className="cart" />
  }

  return (
    <div ref={cartRef} className={styles.toggleCart} role="cart">
      <button
        aria-label={header}
        className={classNames({ on: isActive })}
        onClick={() => !isLoading && setActive("cart", !isActive)}>
        <Icon {...{ isActive, isLoading }} />
        <span className="sr-only" aria-hidden="true">
          {header}
        </span>
        {!isActive && items_count ? (
          <span className="badge">
            {+items_count <= 99 ? items_count : "99+"}
          </span>
        ) : null}
      </button>
      <MiniCart isOpen={isActive} setActive={setActive} />
    </div>
  )
}

export default ToggleCart
